import React, { useState } from "react";
import AstroCulture from "../../assets/images/astroculture.png";

type Props = {};

const Header = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onClick = () => {
    window.open("https://wa.me/918550001300", "_blank", "noopener,noreferrer");
  };

  return (
    <header className="flex justify-between items-center md:px-24 px-4 py-4 w-full z-10 top-0 bg-transparent" >
      <div className="flex items-center">
        <div className="flex items-center">
          <img src={AstroCulture} alt="App Logo" className="h-8 mr-2" />
          <span className="text-xl font-semibold font-zilla text-gray-800">AstroCulture</span>
        </div>

        <nav className="hidden md:flex space-x-6 ml-8">
          <a href="#home" className="text-[#475467] font-inter font-medium hover:text-gray-900">
            Home
          </a>
          {/* <a href="#products" className="text-[#475467] font-inter font-medium hover:text-gray-900">
            Products
          </a>
          <a href="#resources" className="text-[#475467] font-inter font-medium hover:text-gray-900">
            Resources
          </a> */}
          <a
            href="https://www.astroculture.in/blog/"
            rel="noopener noreferrer"
            className="text-[#475467] font-inter font-medium hover:text-gray-900"
          >
            Blogs
          </a>
        </nav>
      </div>

      <div className="hidden md:flex items-center">
        <button
        style={{
          backgroundColor: 'rgba(103, 84, 189, 1)',
        }}
          className="header-btn bg-purple-700 text-white border-none px-5 py-2 rounded-md transition duration-300 ease-in-out hover:bg-purple-400 cursor-pointer text-lg"
          onClick={onClick}
        >
          Chat Now
        </button>
      </div>

      {/* Hamburger Icon for Mobile */}
      <div className="md:hidden text-3xl cursor-pointer" onClick={toggleMenu}>
        &#9776; {/* This is the hamburger icon (three lines) */}
      </div>

      {/* Mobile Navigation */}
      {isOpen && (
        <nav className="absolute top-16 left-0 w-full bg-[#FFF6F0] shadow-lg p-5 flex flex-col space-y-4 md:hidden">
          <a
            href="#home"
            className="text-gray-600 hover:text-gray-900"
            onClick={() => setIsOpen(false)}
          >
            Home
          </a>
          {/* <a
            href="#products"
            className="text-gray-600 hover:text-gray-900"
            onClick={() => setIsOpen(false)}
          >
            Products
          </a>
          <a
            href="#resources"
            className="text-gray-600 hover:text-gray-900"
            onClick={() => setIsOpen(false)}
          >
            Resources
          </a> */}
          <a
            href="https://www.astroculture.in/blog/"
            rel="noopener noreferrer"
            className="text-gray-600 hover:text-gray-900"
            onClick={() => setIsOpen(false)}
          >
            Blogs
          </a>
        </nav>
      )}
    </header>
  );
};

export default Header;
