import React from "react";
import "./cards.css";
import Section from "../section.tsx";
type Props = {
  name: string;
  profession: string;
  experience: string;
  detail: string;
  rating?: string;
  imgUrl?: string;
  type?: string;
  videoUrl?: String;
};

const AstrologerCard = (props: Props) => {
  const { name, profession, experience, rating, imgUrl } = props;

  return (
    <div className="bg-[#E3DFF0] border border-[rgba(103,84,189,0.56)] rounded-3xl shadow-md max-w-[343px] md:max-w-[400px] lg:max-w-[430px] w-full overflow-hidden transition-shadow hover:shadow-lg m-4">
      {/* Image Section */}
      <div className="relative w-[360] h-[270px] sm:h-[240px] md:h-[280px] lg:h-[300px] overflow-hidden mx-2 mt-2">
        <img
          src={imgUrl}
          alt={name}
          className="w-full h-full object-cover object-center rounded-3xl "
        />
      </div>

      {/* Info Section */}
      <div className="p-4 flex-row ">
        {/* Left Column: Name and Profession */}
        <div>
          <h2 className="text-2xl font-medium text-black">
            {name}
          </h2>
          <p className="text-gray-600  mt-2 text-sm sm:text-base font-inter ">
            {profession}
          </p>
        </div>

        {/* Right Column: Rating and Experience */}
        <div className="flex flex-col items-end">
          {/* Rating Box */}
          <div className="bg-[#705FBD] text-white px-2  rounded-md font-normal text-sm flex items-center mb-2 font-inter">
            <span className="mr-[1px] text-base md:mt-[1px]">4.9</span>{" "}
            {/* Rating number with text-base */}
            <span className="md:text-sm text-[12px] mt-[1px] md:mt-1 pb-[2px]">⭐</span>{" "}
            {/* Smaller star with text-sm */}
          </div>

          {/* Experience */}
          <span className="text-black text-sm font-inter font-medium sm:text-sm md:text-base mt-2  ">
            Exp: {experience} Years
          </span>
        </div>
      </div>
    </div>
  );
};

type AstrologersProp = {
  heading: string;
  description: string;
  list: Props[];
};

const Astrologers = ({ heading, description, list }: AstrologersProp) => {
  return (
    <div className="mt-64 md:m-4 p-1">
      <Section heading={heading} description={description} />
      <div className="flex flex-wrap md:flex-row gap-8 justify-center ">
        {list.map((info, index) => (
          <AstrologerCard key={index} {...info} />
        ))}
      </div>
    </div>
  );
};

export default Astrologers;
