import React from "react"
import "./footer.css"
import AstroCultureWhiteBg from "./../../assets/images/astroculture_white_bg.png"
import useMediaQuery from '../../utils/customHooks/useMediaQuery.ts';

const Footer = () => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const handleClick = () => () => {
        window.open('https://wa.me/918550001300', '_blank', 'noopener,noreferrer'); 
    };
    return (
        <><footer className="footer mt-4 px-4 md:px-16 py-10">
            <div className="flex justify-center">
                <img className=" md:max-w-13 w-10 h-10" src={AstroCultureWhiteBg} alt="App Logo" />
            </div>
            <p className="py-3 font-semibold text-3xl">Culture of Authentic Astrology</p>
            <p>Join over 4,000+ people already using AstroCulture.</p>

            <button className="footer-btn px-2 py-2 font-zilla font-semibold"  onClick={handleClick()}>Talk with Astrologer</button>

            <nav className="footer-links">
                <a href="#home">Home</a> 
                {/* <a href="#products">Products</a>
                <a href="#resources">Resources</a> */}
                <a href="https://www.astroculture.in/blog/" rel="noopener noreferrer">Blogs</a>
            </nav>

            <div className="bottom-row md:flex-row flex-col gap-4">
                {!isMobile && <p>© 2024 AstroCulture. All rights reserved.</p>}
                
                <nav className="bottom-links">
                    <a style={{ display: "table-cell" }} href="https://www.astroculture.in/terms-conditions/" 
                        rel="noopener noreferrer">Terms & Conditions</a>
                    <a style={{ display: "table-cell" }} href="https://www.astroculture.in/privacy-policy/"
                        rel="noopener noreferrer">Privacy Policy</a>
                    <a style={{ display: "table-cell" }} href="https://www.astroculture.in/about-us/"
                        rel="noopener noreferrer">About Us</a>
                </nav>
                {isMobile && <p>© 2024 AstroCulture. All rights reserved.</p>}
            </div>
        </footer><></></>
    )
}

export default Footer