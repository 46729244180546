import React from "react";
// import Section from '../whyAstroSection'
import WhySection from "../whyAstroSection";
// import Section from '../section.tsx'

type ReasonItem = {
  title: string;
  description: string;
  imgUrl?: string;
};

const Reason = (props: ReasonItem) => {
  const { title, description, imgUrl } = props;
  return (
    <div className="shadow-lg rounded-2xl bg-white text-black flex-1 text-center w-full">
      <img src={imgUrl} className="rounded-2xl" />
      <p className="md:text-4xl text-lg pt-3 md:pt-6 md:pb-8 pb-2 px-5 font-zilla font-semibold">
        {title}
      </p>
      <p className="md:text-lg md:pb-6 text-xs pb-3 px-4 text-[#362F3E] font-inter font-normal md:px-2">
        {description}
      </p>
    </div>
  );
};

type WhyAstroListProp = {
  heading: string;
  description: string;
  list: ReasonItem[];
};

const WhyAstroList = ({ heading, description, list }: WhyAstroListProp) => {
  return (
    <div>
      <div className="text-center mt-24  flex items-center gap-x-4 flex-col">
        <span className="px-4 text-3xl font-zilla font-semibold mb-2 ">
          {heading}
        </span>
        <span className="text-[#475467] p-2 font-inter font-normal text-xl justify-center align-middle mb-8">
          {description}
        </span>
      </div>
      <div className="md:flex md:flex-row md:flex-wrap flex flex-col gap-6 mx-4 md:mx-6">
        {list.map((info, index) => (
          <Reason key={index} {...info} />
        ))}
      </div>
    </div>
  );
};

const styles = {
  image: {
    width: "100%",
    height: "416px",
    borderRadius: "8px 8px 0 0",
  },
  item: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    background: "white",
    borderRadius: "16px",
  },
};

export default WhyAstroList;
