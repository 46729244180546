import React from "react";
import useMediaQuery from "../../utils/customHooks/useMediaQuery.ts";
import verify from "../../assets/images/verify.png";
import femaleicon from '../../assets/images/femaleIcon.jpg'
import maleIcon from '../../assets/images/maleIcon.jpg'

type FeedbackItem = {
  name: String;
  imgUrl?: String;
  userType?: String;
  feedback: String;
  date?: String;
  redirectionType?: string; // "app" | "web",
  deeplink?: String;
  isVerified:boolean;
};

const FeedbackCard = (props: FeedbackItem) => {
  const { name, feedback, imgUrl, isVerified, designation, date, userType } = props;
  const icon = userType == '1' ? maleIcon :femaleicon;

  return (
    <div
      className="feedback-card bg-white rounded-xl p-4 max-w-sm font-sans h-auto py-6"
      style={{
        boxShadow: `
          0px 0px 0px 1px #EAEAEA,
          0px 1px 3px 0px #8F8F8F33,
          0px -2.4px 0px 0px #3D3D3D0A inset,
          0px 6px 13px 0px #0000000A,
          0px 53px 32px 0px #00000005
        `,
      }}
    >
      <div className="flex items-center mb-3">
        {imgUrl?.length>0 ? <img
          src= {imgUrl}
          alt={`${name}'s avatar`}
          className="w-12 h-12 rounded-full mr-3"
        />:<img
        src= {icon}
        alt={`${name}'s avatar`}
        className="w-12 h-12 rounded-full mr-3"
      />}
        <div className="flex flex-col">
          <div className="flex items-center">
            <p className="text-sm font-medium font-inter">{name}</p>
            {isVerified && (
              <img
                src={verify}
                alt="Verified"
                className="w-4 h-4 ml-1"
              />
            )}
          </div>
        </div>
      </div>
      <p className="text-[#383838] text-[16px] font-inter font-medium mb-4">
        {feedback}
      </p>
      <div className="flex justify-between items-center text-gray-500 text-xs">
        <span>{date}</span>
        <a href="https://wa.me/918550001300" className="text-indigo-600 font-semibold"
            target="_blank"
            rel="noopener noreferrer">
        Book Same astro →
        </a>
      </div>
    </div>
  );
};

type FeedbacksProp = {
  heading: string;
  description: string;
  list: FeedbackItem[];
  imgUrl: string;
  isVerified:boolean,
  date:string,
  // designation: string
};

const Feedbacks = (props: FeedbacksProp) => {
  const { list: feedbackList, heading, description } = props;
  const isMobile = useMediaQuery('(max-width: 768px)');
  const divideIntoColumns = (list: Array<any>, numOfColumns: number) => {
    const columns = Array.from({ length: numOfColumns }, () => []);
    list.forEach((item, index) => {
        columns[index % numOfColumns].push(item);
    });
    return columns;
};

const columns = divideIntoColumns(feedbackList, 3);
  return (
    <div>
      <div className="text-center mt-24 flex items-center gap-x-4 flex-col">
        <span className="px-4 text-3xl font-zilla font-semibold mb-2 ">
          {heading}
        </span>
        <span className="text-[#475467] p-2 font-inter font-normal text-[16px] justify-center align-middle mb-8">
          {description}
        </span>
      </div>
      {isMobile?<div className="flex flex-wrap md:flex-row flex-col gap-8 mx-4 md:mx-32 md:justify-between items-center justify-center pb-8">
        {feedbackList.map((info, index) => (
          <FeedbackCard key={index} {...info} />
        ))}
      </div>:<div className="flex gap-8 justify-between mx-4 md:mx-32 pb-8">
                {columns.map((column, colIndex) => (
                    <div key={colIndex} className='flex flex-col w-full md:w-1/3 gap-3'>
                        {column.map((info, index) => (
                            <FeedbackCard key={index} {...info} />
                        ))}
                    </div>
                ))}
            </div>}
    </div>
  );
};

export default Feedbacks;
