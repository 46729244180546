import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

// Get the root element from the DOM
const rootElement = document.getElementById('root');

// Create a root using createRoot (new in React 18)
const root = createRoot(rootElement);

// Render the app inside the root
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
