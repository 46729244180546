import React from 'react'

import Astrologers from '../../components/cards/astrologer.tsx'
import Blogs from '../../components/cards/blog.tsx'
import FeatureList from '../../components/cards/feature.tsx'
import Feedbacks from '../../components/cards/feedback.tsx'
import WhyAstroList from '../../components/cards/whyAstro.tsx'
import FaqList from '../../components/faqItem.tsx'
import Config from '../../config/homePage.json'
import "./homePage.css"

type Props = {}

const HomePage = (props: Props) => {

  const { landingPages, ourAstrologers, whyAstroCulture, customerFeedback, faq, ourBlogs } = Config

  console.log("Is it coming her")

  return (
    <div className='mt-10'>
      {!!landingPages && <FeatureList featureList={landingPages} />},
      {!!ourAstrologers && <Astrologers {...ourAstrologers} />}
      {!!whyAstroCulture && <WhyAstroList {...whyAstroCulture} />}
      {!!customerFeedback && <Feedbacks {...customerFeedback} />}
      {!!ourBlogs && <Blogs {...ourBlogs} />}
      {!!faq && <FaqList {...faq} />}

    </div >
  )
}

export default HomePage
