import React from 'react';

type FeatureItem = {
    heading: string,
    description: string,
    imgUrl: string,
    actionButtons: ActionButton[]
}

type ActionButton = {
    title: string,
    img: string,
    redirectionUrl: string
}

const FeatureCard = (props: FeatureItem) => {
    const { heading, description, imgUrl, actionButtons } = props;

    const handleClick = ({ redirectionUrl }) => () => {
        window.open(redirectionUrl, '_blank', 'noopener,noreferrer'); // Opens link in a new tab
    };

    return (
        <div className=" bg-card md:pt-32 md:pl-24 md:justify-center md:gap-16 md:m-8 md:h-160 md:h-[590px] mt-0"  >
        <div className='flex flex-col md:flex-row gap-8 md:gap-16 bg-card h-96 pt-16 px-4 md:p-0 md:w-3/4 md:pr-96 md:align-middle'>
            <div className='feature-content'>
                <p className=' px-0 text-center md:text-left feature-title p md:px-0 font-zilla font-semibold text-[36px]'>{heading}</p>
                <p className='feature-desc text-inverted-secondary md:mx-0 mx-6 font-inter font-normal text-[20px]'>{description}</p>
                <div className='feature-button-container'>
                    {actionButtons.map(item => (
                        <button
                        key={item.title}
                        className="px-4 py-2 text-white font-medium rounded-lg border transition-colors duration-300
                                   border-[#7F56D9] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] 
                                   bg-[#7F56D9] bg-gradient-to-b from-[#7F56D9] to-[#7F56D9] 
                                   hover:bg-purple-700"
                        onClick={handleClick(item)}
                      >
                        {item.title}
                      </button>
                    ))}
                </div>
            </div>
            <div className='md:feature-image-wrapper mx-8 md:absolute md:right-40 md:top-36'>
                <img className='feature-image md:h-4' src={imgUrl} alt={heading} />
            </div>
        </div>
        </div>
    );
}

type FeatureListProp = {
    featureList: FeatureItem[]
}

const FeatureList = (props: FeatureListProp) => {
    const { featureList } = props;
    return (
        <div className='rounded-3xl bg-card'>
            {featureList.map((info, index) => (
                <FeatureCard
                    key={index}
                    {...info}
                />
            ))}
        </div>
    );
}

export default FeatureList;
