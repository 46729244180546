import React, { useState } from "react";

import Plus from "./../assets/images/plus-circle.png";
import Minus from "./../assets/images/minus-circle.png";
import Section from "./section.tsx";

type FaqItemProps = {
  question: String;
  type?: String;
  answer?: String;
  subView?: SubView[];
};

type SubView = {
  type: string;
  title?: string;
  text: string;
};

const ItemSubView = (item: SubView) => {
  const { type, title, text } = item;
  switch (type) {
    case "DESC":
      return (
        <p className="pb-5 font-inter font-normal text-secondary text-lg">
          {text}
        </p>
      );
    case "POINT":
      return (
        <div className="flex flex-1 flex-row gap-2 items-start ">
          <p>{`\u25CF`}</p>
          <p className="pb-5 font-inter font-normal text-secondary text-lg ">
            <strong className="font-bold">{title} </strong>
            {text}
          </p>
        </div>
      );
    default:
      return null;
  }
};

const FaqItem = (props: FaqItemProps) => {
  const { type = "default", question, answer, subView } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const onPress = () => {
    setIsExpanded(!isExpanded);
  };
  

  return (
    <div className="content_60 divider pb-2">
      <div
        className="flex-row gap-3 justify-between items-start"
        onClick={onPress}
      >
        <p className="text-xl font-zilla font-medium py-3">{question}</p>
        <img
          src={isExpanded ? Minus : Plus}
          style={{ width: 20, height: 20 }}
        />
      </div>

      {isExpanded ? (
        type == "default" ? (
          <p className="pb-5 font-inter font-normal text-secondary md:text-lg text-[16px] ">
            {answer}
          </p>
        ) : (
          subView?.map((item) => <ItemSubView {...item} />)
        )
      ) : null}
    </div>
  );
};

type FaqsProp = {
  heading: string;
  description: string;
  list: FaqItemProps[];
};

const FaqList = (props: FaqsProp) => {
  const { list: faqsList, heading, description } = props;
  return (
    <div>
      <div className="text-center mt-24  flex items-center gap-x-4 flex-col">
        <span className="px-2 text-3xl font-zilla font-semibold mb-2 ">
          {heading}
        </span>
        <span className="text-[#475467] py-2 px-2 mt-2 font-inter font-normal text-[18px] justify-center align-middle mb-6">
          {description}
        </span>
      </div>
      <div className="px-2">
        {faqsList.map((info, index) => (
          <FaqItem key={index} {...info} />
        ))}
      </div>
    </div>
  );
};

export default FaqList;
