import React from 'react'

type Props = {}

const ProductsPage = (props: Props) => {
  return (
    <div>


    </div>
  )
}

export default ProductsPage