import React from "react";

type ArrowProps = {
    onClick?: () => void;
};

const CustomPrevArrow = ({ onClick }: ArrowProps) => (
    <button
        onClick={onClick}
        className="absolute bottom-[-50px]  h-12 w-12 left-[45%]  transform -translate-x-[2rem] p-2 text-lg bg-white rounded-full shadow-md hover:bg-gray-100"
        aria-label="Previous"
    >
        ←
    </button>
);

const CustomNextArrow = ({ onClick }: ArrowProps) => (
    <button
        onClick={onClick}
        className="absolute bottom-[-50px] left-[45%] h-12 w-12 -1/2 transform translate-x-[2rem] p-2 text-lg bg-white rounded-full shadow-md hover:bg-gray-100"
        aria-label="Next"
    >
        →
    </button>
);

export { CustomPrevArrow, CustomNextArrow };
