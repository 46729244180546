import React from 'react';
import Slider from 'react-slick';
import Section from '../section.tsx';
import useMediaQuery from '../../utils/customHooks/useMediaQuery.ts';
import { CustomPrevArrow, CustomNextArrow } from '../buttons/CustomPrevArrow.tsx';
// import useMediaQuery from '../useMediaQuery';

type BlogItem = {
    title: string,
    description: string,
    redirectionUrl: string,
    imgUrl: string
}

const BlogCard = (props: BlogItem) => {
    const { title, description, redirectionUrl, imgUrl } = props;

    return (
        <div className='blogs shadow-md m-4'>
            <img src={imgUrl} alt={title} className='h-60 w-[340px] rounded-3xl' />
            <p className='two-line pt-4 text-2xl font-medium mt-4'>{title}</p>
            <p className='text-[#333333] text-sm pt-4 font-inter font-normal three-line mb-6'>{description}</p>
            <a className='text-link2 font-inter font-semibold' href={redirectionUrl} rel="noopener noreferrer">Read post...</a>
        </div>
    );
}

type BlogsProp = {
    heading: string,
    description: string,
    list: BlogItem[]
}

const Blogs = (props: BlogsProp) => {
    const { list: blogsList, heading, description } = props;

    // Detect if the screen width is less than 768px (mobile view)
    const isMobile = useMediaQuery('(max-width: 768px)');

    // Carousel settings for mobile view
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        prevArrow: <CustomPrevArrow />, 
        nextArrow: <CustomNextArrow />  
    };

    return (
        <div>
            <Section heading={heading} description={description} />
            {isMobile ? (
                <div className="relative">
                    <Slider {...settings} className='carousel-container'>
                        {blogsList.map((info, index) => (
                            <div key={index} className="pr-8">
                                <BlogCard {...info} />
                            </div>
                        ))}
                    </Slider>
                </div>
            ) : (
                <div className='flex md:flex-row gap-6 justify-center items-center'>
                    {blogsList.map((info, index) => (
                        <BlogCard
                            key={index}
                            {...info}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default Blogs;
