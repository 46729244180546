import React from 'react'

type Props = {
    heading: String,
    description: String
}

const Section = (props: Props) => {
    const {heading, description} = props
    return (
        <div className="section mt-64 flex items-center gap-x-4 flex-col">
  <span className="p-2 text-4xl font-zilla font-semibold">{heading}</span>
  <span className="text-[#475467] p-2 font-inter font-normal text-xl">{description}</span>
</div>
    )
}

export default Section